import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import Camera from "simple-vue-camera";

//date picter
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
VueDatePicker.locale = 'id'

//easy data table
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

//multiselect
import Multiselect from "vue-multiselect";
import "../public/css/vue-multiselect.custom.css";

const app = createApp(App)
app.component('VueDatePicker', VueDatePicker)
app.component("Multiselect", Multiselect);
app.component('EasyDataTable', Vue3EasyDataTable);
// app.component("camera", Camera);

//axios
import ip from "@/axios/index.js";
app.config.globalProperties.$axios = ip;

//ipbacked
import ipBackend from "@/ipBackend.js";
app.config.globalProperties.$be = ipBackend;

//moment
import moment from "moment";
import "moment/locale/id";
app.config.globalProperties.$moment = moment;

//number format
import numberFormat from "./functions/numberFormat.js";
app.config.globalProperties.$numberFormat = numberFormat.methods.number

//color status
import colorStatus from "./functions/colorStatus.js";
app.config.globalProperties.$colorStatus = colorStatus

//swal (modal)
import Swal from 'sweetalert2'
app.config.globalProperties.$Swal = Swal

//angka terbilang (number angka terbilang / 1000 menjadi seribu)
import angkaTerbilang from '@develoka/angka-terbilang-js'; 
app.config.globalProperties.$toWord = angkaTerbilang;

//primevue
import "primevue/resources/themes/lara-light-indigo/theme.css";
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import Chart from 'primevue/chart';
app.use(PrimeVue);
app.directive('tooltip', Tooltip);
app.component('Dialog', Dialog)
app.component('Chart', Chart);

app.use(store)
app.use(router)
app.mount('#app')
