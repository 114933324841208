import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import axios from 'axios'
import ip from '@/ipBackend.js'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('../views/Empty.vue')
    },
    {
        path: '/formulir',
        name: 'formulir',
        component: () => import('../views/hcms/pengajuanDinas/formulir/inputFormulir.vue')
        // component: () => import('@/views/Empty.vue')
    },
    {
        path: '/daftar-pengajuan-dinas-anda',
        name: 'Daftar Pengajuan Dinas Anda',
        component: () => import('../views/hcms/pengajuanDinas/pengajuanDinasPembuat.vue')
    },
    {
        path: '/daftar-pengajuan-dinas-pelaksana',
        name: 'Daftar Pengajuan Dinas Pelaksana',
        component: () => import('../views/hcms/pengajuanDinas/pengajuanDinasPelaksana.vue')
    },
    {
        path: '/daftar-pengajuan-dinas-anggota',
        name: 'Daftar Pengajuan Dinas Pembuat',
        component: () => import('../views/hcms/pengajuanDinas/pengajuanDinasPengikut.vue')
    },
    {
        path: '/daftar-pengajuan-dinas-unit',
        name: 'Daftar Pengajuan Dinas Unit',
        component: () => import('../views/hcms/pengajuanDinas/pengajuanDinasUnit.vue')
    },
    {
        path: '/laporan-selfie-pengajuan-dinas/:formulir_id',
        name: 'Laporan Selfie Pengajuan Dinas',
        component: () => import('../views/hcms/pengajuanDinas/laporanSelfie.vue')
    },
    {
        path: '/laporan-berkas-pengajuan-dinas/:formulir_id',
        name: 'Laporan Berkas Pengajuan Dinas',
        component: () => import('../views/hcms/pengajuanDinas/laporanBerkas.vue')
    },
    {
        path: '/perintah-spd/:formulir_id',
        name: 'Perintah SPD',
        component: () => import('../views/hcms/print/1perintahSPD.vue')
    },
    {
        path: '/surat-pelaksana-kegiatan/:formulir_id',
        name: 'Surat Pelaksana',
        component: () => import('../views/hcms/print/2suratPelaksanaKegiatan.vue')
    },
    {
        path: '/komitmen-spj-spd/:formulir_id',
        name: 'Komitmen SPJ SPD',
        component: () => import('../views/hcms/print/3komitmenSPJ_SPD.vue')
    },
    {
        path: '/form-pengeluaran-rill/:formulir_id',
        name: 'Form Pengeluaran Rill',
        component: () => import('../views/hcms/print/4formPengeluaranRiil.vue')
    },
    {
        path: '/rincian-perjalanan-dinas/:formulir_id',
        name: 'Rincian Perjalanan Dinas',
        component: () => import('../views/hcms/print/5rincianPerjalananDinas.vue')
    },
    {
        path: '/stpjm-penerima-uang-taxi/:formulir_id',
        name: 'STPJM Penerima Uang Taxi',
        component: () => import('../views/hcms/print/6STPJM_penerimaUangTaxi.vue')
    },
    {
        path: '/kuitansi/:formulir_id',
        name: 'Kuitansi',
        component: () => import('../views/hcms/print/7kuitansi.vue')
    },
    {
        path: '/test',
        name: 'Test',
        component: () => import('../views/hcms/testku.vue')
    },
    // {
    //     path: '/formulir',
    //     name: 'formulir',
    //     // beforeEnter : (to,form,next) => {
    //     //     const tutam = store.getters.getTutam
    //     //     if(tutam) {
    //     //         if(store.getters.getSupervisor)
    //     //             next({name:'formulir-tutam', params : { tutam : 'supervisor' }})
    //     //         else if (store.getters.getManajer)
    //     //             next({name:'formulir-tutam', params : { tutam : 'manajer' }})
    //     //         else if(getKabiro)
    //     //             next({name:'formulir-tutam', params : { tutam : 'kepala-biro' }})
    //     //         else
    //     //             next()
    //     //     } else {
    //     //         next()
    //     //     }
    //     // },
    //     component: () => import('../views/hcms/pengajuanDinas/formulir/inputFormulir.vue')
    // },
    // {
    //     path: '/formulir',
    //     name: 'formulir',
    //     // beforeEnter : (to,form,next) => {
    //     //     const tutam = store.getters.getTutam
    //     //     if(tutam) {
    //     //         if(store.getters.getSupervisor)
    //     //             next({name:'formulir-tutam', params : { tutam : 'supervisor' }})
    //     //         else if (store.getters.getManajer)
    //     //             next({name:'formulir-tutam', params : { tutam : 'manajer' }})
    //     //         else if(getKabiro)
    //     //             next({name:'formulir-tutam', params : { tutam : 'kepala-biro' }})
    //     //         else
    //     //             next()
    //     //     } else {
    //     //         next()
    //     //     }
    //     // },
    //     component: () => import('../views/hcms/pengajuanDinas/formulir/inputFormulir.vue')
    // },
    // {
    //     path: '/formulir1',
    //     name: 'formulir1',
    //     beforeEnter : (to,form,next) => {
    //         const tutam = store.getters.getTutam
    //         if(tutam) {
    //             if(store.getters.getSupervisor)
    //                 next({name:'formulir-tutam', params : { tutam : 'supervisor' }})
    //             else if (store.getters.getManajer)
    //                 next({name:'formulir-tutam', params : { tutam : 'manajer' }})
    //             else if(getKabiro)
    //                 next({name:'formulir-tutam', params : { tutam : 'kepala-biro' }})
    //             else
    //                 next()
    //         } else {
    //             next()
    //         }
    //     },
    //     component: () => import('../views/FormulirDinasView.vue')
    // },
    // {
    //     path: '/ajuanunit',
    //     name: 'ajuanunit',
    //     component: () => import('../views/DaftarAjuanUnitView.vue')
    // },
    // {
    //     path: '/laporan',
    //     name: 'laporan',
    //     component: () => import('../views/laporan/ListReportView.vue')
    // },
    // {
    //     path: '/formulirselfie/:id',
    //     name: 'formulirselfie',
    //     component: () => import('../views/LaporanView.vue')
    // },
    // {
    //     path: '/estimasi/:formulir_id',
    //     name: 'estimasi',
    //     // beforeEnter : (to,form,next) => { const spv = store.getters.getSupervisor; if(spv) {next()} else {next({name:'home'})}},
    //     component: () => import('../views/ApprovalSupervisorView.vue')
    // },
    // {
    //     path: '/approval/:formulir_id',
    //     name: 'approval',
    //     beforeEnter : (to,form,next) => { const mnj = store.getters.getManajer; if(mnj) {next()} else {next({name:'home'})}},
    //     component: () => import('../views/ApprovalManajerView.vue')
    // },
    // {
    //     path: '/report/:formulir_id',
    //     name: 'report',
    //    // beforeEnter : (to,form,next) => { const mnj = store.getters.getManajer; if(mnj) {next()} else {next({name:'home'})}},
    //     component: () => import('../views/laporan/ReportView.vue')
    // },
    // {
    //     path: '/formulir/:tutam',
    //     name: 'formulir-tutam',
    //     beforeEnter : (to,form,next) => {
    //         const tutam = store.getters.getTutam
    //         if(tutam) {
    //             if(store.getters.getSupervisor)
    //                 next()
    //             else if (store.getters.getManajer)
    //                 next()
    //             else if(getKabiro)
    //                 next()
    //             else
    //                 next({name:'formulir'})
    //         } else {
    //             next({name:'formulir'})
    //         }
    //     },
    //     component: () => import('../views/FormulirDinasWithEstimasiView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to,from,next)=>{
    const token = store.getters.getToken
    if(to.name === 'login'){
        next()
    } else {
        if(!token){
            next({name : 'login'})
        } else {
            var response = await axios.post(ip + 'users/validate',{ token })
            if(response.data){
                next()
            } else {
                window.localStorage.removeItem('vuex');
                next({name : 'login'})
            }
        }
    }
})

export default router