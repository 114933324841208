<template>
   <router-view/>
</template>

<script>
// $(document).ready(function() {
//     $("body").tooltip({ selector: '[data-toggle=tooltip]' });
// });
export default {
  mounted() {
  }
}
</script>