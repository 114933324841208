export default function (status) {
  switch (status) {
    case 1:
    case 5:
      return 'btn-primary'
    case 2:
    case 6:
      return 'btn-info'
    case 3:
    case 7:
      return 'btn-warning'
    case 4:
    case 8:
      return 'btn-success'
    case 9:
      return 'btn-danger'
    default:
      return 'error'
  }
}