import axios from "axios";
import store from "@/store/index.js";
import ip from '@/ipBackend.js'

// console.log('ip', ip)

const instance = axios.create({
  baseURL: ip,
});
instance.interceptors.request.use(async (req) => {  
  req.headers.authorization = store.getters.getToken;
  return req;
});

export default instance;
