import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import ip from '@/ipBackend.js'

export default createStore({
    state: {
        serverURL   : ip,
        token       : null,
        ID          : null,
        nip         : null,
        nama        : null,
        foto        : null,
        tutam       : null,
        supervisor  : null,
        manajer     : null,
        kabiro      : null
    },
    getters: {
        getServerURL(state){ return state.serverURL },

        getToken(state){ return state.token },
        getId(state){ return state.ID },
        getNip(state){ return state.nip },
        getNama(state){ return state.nama },
        getFoto(state){ return state.foto },
        getTutam(state){ return state.tutam },
        getSupervisor(state){ return state.supervisor },
        getManajer(state){ return state.manajer },
        getKabiro(state){ return state.kabiro }
    },
    mutations: {
        mutToken(state,payload){ state.token = payload },
        mutId(state,payload){ state.ID = payload },
        mutNip(state,payload){ state.nip = payload },
        mutNama(state,payload){ state.nama = payload },
        mutFoto(state,payload){ state.foto = payload },
        mutTutam(state,payload){ state.tutam = payload },
        mutSupervisor(state,payload){ state.supervisor = payload },
        mutManajer(state,payload){ state.manajer = payload },
        mutKabiro(state,payload){ state.kabiro = payload }
    },
    actions: {
        setToken(context,payload){ context.commit('mutToken',payload) },
        setId(context,payload){ context.commit('mutId',payload) },
        setNip(context,payload){ context.commit('mutNip',payload) },
        setNama(context,payload){ context.commit('mutNama',payload) },
        setFoto(context,payload){ context.commit('mutFoto',payload) },
        setTutam(context,payload){ context.commit('mutTutam',payload) },
        setSupervisor(context,payload){ context.commit('mutSupervisor',payload) },
        setManajer(context,payload){ context.commit('mutManajer',payload) },
        setKabiro(context,payload){ context.commit('mutKabiro',payload) }
    },
    modules: {},
    plugins: [createPersistedState()],
})